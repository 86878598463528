@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
html, #root {
  height: 100vh; }

body {
  color: #0a0a0a;
  overflow: hidden;
  height: 100vh;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: -webkit-filter 1s ease-in-out;
  transition: filter 1s ease-in-out;
  transition: filter 1s ease-in-out, -webkit-filter 1s ease-in-out;
  -webkit-filter: blur(0);
          filter: blur(0); }
  body.blur {
    -webkit-filter: blur(5px);
            filter: blur(5px); }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }


.Breadcrumbs ol {
  white-space: nowrap;
  flex-wrap: nowrap; }

.ic-follow {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center center; }

.ic-follow-812 {
  background-image: url(/static/media/ic-follow-812.4d9d2889.svg); }

.ic-follow-930 {
  background-image: url(/static/media/ic-follow-930.d0100a70.svg); }

.ic-follow-1073 {
  background-image: url(/static/media/ic-follow-1073.01d04a81.svg); }

.ic-follow-1123 {
  background-image: url(/static/media/ic-follow-1123.18a69168.svg); }

.ic-follow-1203 {
  background-image: url(/static/media/ic-follow-1203.0bbe186b.svg); }

.ic-follow-1374 {
  background-image: url(/static/media/ic-follow-1374.4d19a381.svg); }

.ic-follow-1380 {
  background-image: url(/static/media/ic-follow-1380.155f15b0.svg); }

.viewer {
  margin-top: 50px;
  width: 100%;
  height: 82%; }
  .viewer img {
    display: block;
    margin: 5px auto;
    max-width: 100%;
    height: auto; }

.box {
  width: 52%;
  margin-top: 2%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  color: white;
  padding: 1.25rem;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 800px) {
    .box {
      width: 100%; } }

.pdf {
  width: 1294px;
  min-height: 67em;
  margin: 30px auto 10px;
  text-align: center; }
  @media only screen and (max-width: 800px) {
    .pdf {
      max-width: 100%;
      max-height: 100%;
      margin: 10px auto 10px; }
      .pdf .navigation {
        margin: 46px 42px 21px 10px;
        text-align: center;
        overflow: hidden;
        position: fixed;
        bottom: 0; } }

@media only screen and (max-width: 800px) {
  .pdfCanvas {
    max-width: 100%;
    max-height: 100%;
    margin: 10px auto 10px; } }

.canvasCustom {
  overflow: hidden;
  background-color: #f1f4f5; }

.lesson-content .iframePdf {
  width: 1294px;
  min-height: 67em;
  margin: 10px auto 10px -99px; }
  @media only screen and (max-width: 800px) {
    .lesson-content .iframePdf {
      max-width: 100%;
      max-height: 100%;
      min-height: 25em;
      margin: 10px auto 10px; } }

.lesson-content h1 {
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
  color: #77C35E; }

.lesson-content h2 {
  font-size: 1.125em;
  font-weight: bold;
  line-height: 1.71;
  color: #77C35E; }

.lesson-content h3 {
  font-size: 1.125em;
  line-height: 1.56; }

.lesson-content h4 {
  font-size: 1em;
  line-height: 1.56; }

.lesson-content p, .lesson-content ul {
  font-size: 1em;
  line-height: 1.56;
  color: #18202f;
  text-align: justify; }

.lesson-content .texte1 {
  color: #77C35E;
  font-size: 1em;
  line-height: 1.56;
  text-align: justify; }

.lesson-content .texte2 {
  color: #263c46;
  font-size: 0.938em;
  line-height: 1.56;
  text-align: justify; }

.lesson-content .texte3 {
  color: #263c46;
  font-size: 0.875em;
  line-height: 1.56;
  text-align: justify; }

.lesson-content img {
  width: 100%;
  height: auto;
  margin: 20px 0 30px 0; }

.lesson-content .exemple {
  background: white; }

.lesson-content p.exemple-titre {
  font-size: 17px;
  color: #111b1f;
  padding: 10px; }

.lesson-content p.exemple-texte {
  font-size: 16px;
  color: #111b1f;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px; }

.lesson-content .bloc-noter {
  background: white; }

.lesson-content p.bloc-noter-titre {
  padding: 10px;
  background: #263c46;
  color: white;
  font-size: 17px;
  font-weight: bold; }

.lesson-content p.bloc-noter-texte {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #263c46;
  font-size: 16px; }

.lesson-content p.bloc-noter-texte1 {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #77C35E;
  font-size: 16px; }

.lesson-content table {
  background: white;
  border-collapse: collapse; }
  .lesson-content table th, .lesson-content table td {
    border: 1px solid black;
    padding: 0 10px;
    font-size: 0.9em; }

.lesson-content table tr.titre1 {
  background: #263c46; }

.lesson-content table .titre1 th {
  color: white;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase; }

.lesson-content table tr.titre2 {
  background: #e7eced; }

.lesson-content table .titre2 th {
  color: #263c46;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase; }

.lesson-content table tr.titre3 {
  background: #f3f5f6; }

.lesson-content table .titre3 th {
  color: #263c46;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase; }

.digischool-quiz-player {
  padding: 5px 10px;
  display: inline-block;
  width: 100%; }
  .digischool-quiz-player .quiz-player {
    display: none; }
    .digischool-quiz-player .quiz-player.active {
      display: block; }
    .digischool-quiz-player .quiz-player .quiz-header {
      z-index: 1;
      position: fixed;
      padding: 0 29px;
      bottom: 10px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #18202f;
      font-weight: normal;
      margin-top: 0.67em;
      margin-bottom: 0.67em; }
      .digischool-quiz-player .quiz-player .quiz-header .question-counter {
        position: absolute;
        bottom: 10px;
        width: 250px; }
    .digischool-quiz-player .quiz-player #quiz-information {
      display: none; }
    .digischool-quiz-player .quiz-player #question-media-container .medias .media {
      margin-top: 30px; }
    .digischool-quiz-player .quiz-player .medias .media {
      width: 100%;
      height: auto; }
      .digischool-quiz-player .quiz-player .medias .media img {
        width: auto;
        max-width: 100%;
        margin: auto;
        display: block; }
      .digischool-quiz-player .quiz-player .medias .media .loadImg {
        margin: 10px 0;
        transition: opacity 1s ease-in-out;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1; }
        .digischool-quiz-player .quiz-player .medias .media .loadImg.loaded {
          opacity: 0; }
    .digischool-quiz-player .quiz-player #question-container p.question-text {
      font-size: 20px;
      font-weight: 600;
      color: #18202f;
      text-align: justify;
      margin-bottom: 30px; }
    .digischool-quiz-player .quiz-player .subquestion-container .subquestion {
      font-size: 20px;
      font-weight: 600;
      color: #121723;
      text-align: justify; }
      .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content {
        padding: 0 25px; }
        .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content p {
          margin: 30px 0 20px 0; }
      .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container {
        padding: 0;
        list-style: none; }
        .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container {
          position: relative;
          cursor: pointer; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response {
            border-left: 10px; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response .title {
              padding-right: 44px; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response:after, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response:after {
              position: absolute;
              top: 50%;
              right: 4px;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              content: '';
              display: block; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid.valid .response, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid.valid .response {
            border: solid 1px #79a611; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid.valid .response:after, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid.valid .response:after {
              background: url(/static/media/ic-good-answer.d8f95472.svg) no-repeat; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid.invalid .response, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid.invalid .response {
            border: solid 1px #eb5d5d; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid.invalid .response:after, .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid.invalid .response:after {
              background: url(/static/media/ic-bad-answer.3d908085.svg) no-repeat; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response {
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            border-left: 8px solid black;
            background-color: #ffffff; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container:hover {
            background-color: rgba(255, 255, 255, 0.65); }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response {
            width: 100%;
            min-height: 50px;
            border-radius: 5px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11);
            border: solid 1px #c3c6cc;
            font-weight: normal; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response:not(:first-child) {
              margin-top: 20px; }
            .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response .title {
              padding: 12px 25px;
              font-size: 16px;
              line-height: 1.63;
              letter-spacing: 0.3px;
              color: #263c46; }
          .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container span {
            display: none; }
    .digischool-quiz-player .quiz-player .btn-next, .digischool-quiz-player .quiz-player .btn-explanation-quiz {
      outline: none;
      cursor: pointer;
      width: 56px;
      height: 70px;
      border: none;
      font: 0/0 a;
      text-shadow: none;
      color: transparent; }
    .digischool-quiz-player .quiz-player .button-wrapper {
      position: absolute;
      bottom: 95px;
      left: 85%;
      z-index: 100; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-explanation-wrapper {
        position: absolute;
        right: 70px; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-next-wrapper {
        position: absolute;
        right: 0; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-next {
        background: url(/static/media/fab-validate.a9f3d954.svg) no-repeat center; }
        .digischool-quiz-player .quiz-player .button-wrapper .btn-next:disabled {
          opacity: 0.80;
          background: url(/static/media/fab-validate-disabled.80c92e13.svg) no-repeat center; }
        .digischool-quiz-player .quiz-player .button-wrapper .btn-next.arrow {
          background: url(/static/media/fab-next.8f538347.svg) no-repeat center; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-explanation-quiz {
        background: url(/static/media/fab-explain.82c2b4ef.svg) no-repeat center; }
    .digischool-quiz-player .quiz-player .clear.result {
      position: fixed;
      bottom: 25px;
      z-index: 101;
      left: 50%;
      margin-left: -400px;
      width: 620px;
      text-align: right; }
      .digischool-quiz-player .quiz-player .clear.result h4 {
        display: none; }

.rating {
  height: 70px; }

.ReactModal__Content {
  max-width: 620px;
  margin: auto;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: auto;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  text-align: center; }
  .ReactModal__Content:not(.quiz-player-explanation) {
    max-width: 90%; }
  .ReactModal__Content h5.modal-explanation-title {
    font-size: 24px;
    text-align: center;
    font-weight: 800;
    color: #79a611; }
  .ReactModal__Content .modal-explanation-btn-close {
    max-width: 150px;
    margin: auto;
    border-radius: 5px;
    width: 100%;
    padding: 12px 0;
    color: white;
    transition: all 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    background: #77C35E; }

.progress-bar {
  bottom: 0;
  position: absolute;
  width: 250px;
  height: 6px; }
  .progress-bar .progress-bar-background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ebedee; }
    .progress-bar .progress-bar-background .progress-bar-line {
      height: 100%;
      background-color: #77C35E; }

.quiz-content {
  padding-bottom: 75px; }


