
$primary-color: #75b519;
$secondary-color: #00f2f2;

// @import '../../../node_modules/@dsk-lib/quizplayer/build/components/QuizComponent/styles/style';
// @import '../../../node_modules/@dsk-lib/quizplayer/build/components/QuizComponent/styles/default.quiz.style.css';

$green : #77C35E;
$mainTextColor : #18202f;


    .digischool-quiz-player {
      padding: 5px 10px;
      display: inline-block;
      width: 100%;
      .quiz-player {
        display: none;
        &.active {
          display: block;
        }
        .quiz-header {
          //display: none;
          z-index: 1;
          position: fixed;
          padding: 0 29px;
          bottom: 10px;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: 0.3px;
          color: $mainTextColor;
          font-weight: normal;
          margin-top: 0.67em;
          margin-bottom: 0.67em;

          .question-counter {
            position: absolute;
            bottom: 10px;
            width: 250px;
          }
        }

        #quiz-information {
          display: none;
        }
  
        #question-media-container {
          .medias {
            .media {
              margin-top: 30px;
            }
          }
        }
  
        .medias {
          .media {
            width: 100%;
            height: auto;
            img {
              width: auto;
              max-width: 100%;
              margin: auto;
              display: block;
            }
  
            .loadImg {
              margin: 10px 0;
              transition: opacity 1s ease-in-out;
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 1;
              &.loaded {
                opacity: 0;
              }
            }
          }
        }
        #question-container {
          p {
            &.question-text {
              font-size: 20px;
              font-weight: 600;
              color: $mainTextColor;
              text-align: justify;
              margin-bottom: 30px;
            }
          }
        }
  
        .subquestion-container {
          .subquestion {
            font-size: 20px;
            font-weight: 600;
            color: #121723;
            text-align: justify;
  
            .subquestion-content{
              padding: 0 25px;
              p{
                margin: 30px 0 20px 0;
              }
            }
  
            .answers-container {
              padding: 0;
              list-style: none;
              .answer-container {
                position: relative;
                cursor: pointer;
  
                &.valid, &.invalid {
                  .response {
                    border-left: 10px;
                    .title {
                      padding-right: 44px;
                    }
                    &:after {
                      position: absolute;
                      top: 50%;
                      right: 4px;
                      transform: translate(-50%, -50%);
                      width: 20px;
                      height: 20px;
                      content: '';
                      display: block;
                    }
                  }
  
                  &.valid {
                    .response {
                      border: solid 1px #79a611;
                      &:after {
                        background: url('./assets/ic-good-answer.svg') no-repeat;
                      }
                    }
                  }
  
                  &.invalid {
                    .response {
                      border: solid 1px #eb5d5d;
                      &:after {
                        background: url('./assets/ic-bad-answer.svg') no-repeat;
                      }
                    }
                  }
                }
  
                &.selected {
                  .response {
                    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                    border-left: 8px solid black;
                    background-color: #ffffff;
                  }
                }
  
                &:hover {
                  background-color: rgba(255, 255, 255, 0.65);
                }
                .response {
                  width: 100%;
                  min-height: 50px;
                  border-radius: 5px;
                  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11);
                  border: solid 1px #c3c6cc;
                  font-weight: normal;
  
                  &:not(:first-child) {
                    margin-top: 20px;
                  }
  
                  .title {
                    padding: 12px 25px;
                    font-size: 16px;
                    line-height: 1.63;
                    letter-spacing: 0.3px;
                    color: #263c46;
                  }
  
                }
                span {
                  display: none;
                }
              }
            }
  
          }
        }
  
        .btn-next, .btn-explanation-quiz {
          outline: none;
          cursor: pointer;
          width: 56px;
          height: 70px;
          border: none;
          font: 0/0 a;
          text-shadow: none;
          color: transparent;
        }
  
        .button-wrapper {
          position: absolute;
          bottom: 95px;
          left: 85%;
          z-index: 100;
  
          .btn-explanation-wrapper {
            position: absolute;
            right: 70px;
          }
          .btn-next-wrapper {
            position: absolute;
            right: 0;
          }
  
          .btn-next {
            background: url('./assets/fab-validate.svg') no-repeat center;
            &:disabled {
              opacity: 0.80;
              background: url('./assets/fab-validate-disabled.svg') no-repeat center;
            }
            &.arrow {
              background: url('./assets/fab-next.svg') no-repeat center;
  
            }
          }
          .btn-explanation-quiz {
            background: url('./assets/fab-explain.svg') no-repeat center;
  
          }
        }
  
        .clear {
          &.result {
            h4 {
              display: none;
            }
  
            position: fixed;
            bottom: 25px;
            z-index: 101;
            left: 50%;
            margin-left: -400px;
            width: 620px;
            text-align: right;
          }
  
        }
      }
    }
  
    .rating {
      height: 70px;
    }
  
  .ReactModal__Content {
    max-width: 620px;
    margin: auto;
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    text-align: center;
  
    &:not(.quiz-player-explanation) {
      max-width: 90%;
    }
  
    h5 {
      &.modal-explanation-title {
        font-size: 24px;
        text-align: center;
        font-weight: 800;
        color: #79a611;
      }
    }
    .modal-explanation-btn-close {
      max-width: 150px;
      margin: auto;
      border-radius: 5px;
      width: 100%;
      padding: 12px 0;
      color: white;
      transition: all 0.2s linear;
      font-size: 14px;
      cursor: pointer;
      font-weight: 600;
      background: $green;
    }
  }
  
  .progress-bar{
    bottom: 0;
    position: absolute;
    width: 250px;
    height: 6px;
    .progress-bar-background{
      width: 100%;
      height: 100%;
      border-radius: 20px;
      overflow: hidden;
      background-color: #ebedee;
      .progress-bar-line{
        height: 100%;
        background-color: $green;
      }
    }
  }

  .quiz-content{
    padding-bottom: 75px;
  }
  